<template>
	<div>
		<TransitionRoot as="template" :show="showPrompt">
			<Dialog
				as="div"
				class="fixed z-10 inset-0 overflow-y-auto"
				:unmount="false"
				:open="showPrompt"
			>
				<div
					class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
				>
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0"
						enter-to="opacity-100"
						leave="ease-in duration-200"
						leave-from="opacity-100"
						leave-to="opacity-0"
					>
						<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</TransitionChild>

					<!-- This element is to trick the browser into centering the modal contents. -->
					<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
						>&#8203;</span
					>
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div
							class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
						>
							<div>
								<div
									class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-sky-100"
								>
									<PencilSquareIcon class="h-6 w-6 text-primary" aria-hidden="true" />
								</div>
								<div class="mt-3 text-center sm:mt-5">
									<DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
										Wijzigen gegevens
									</DialogTitle>
									<div class="mt-2">
										<PulseLoader
											v-if="loading"
											color="#009ee0"
											class="mt-8 mb-8 text-center w-full"
										></PulseLoader>
										<div v-show="!loading && !success" class="max-w-3xl mx-auto">
											<div class="bg-white">
												<form class="space-y-8 divide-y divide-gray-200">
													<div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
														<div>
															<div class="sm:mt-5 space-y-6 sm:space-y-5">
																<div
																	class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
																>
																	<label
																		for="username"
																		class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
																	>
																		Categorie
																		<span v-if="fieldIsRequired('category')" class="text-red-600"
																			>*</span
																		>
																	</label>
																	<div class="mt-1 sm:mt-0 sm:col-span-2">
																		<div class="max-w-lg flex">
																			<select
																				v-model="selectedCategory"
																				:class="[
																					categoryError ? 'border-red-600' : 'border-gray-300',
																					'rounded-md shadow-xs w-full focus:ring-primary'
																				]"
																			>
																				<option v-for="item in items" :key="item.id" :value="item">
																					{{ item.val }}
																				</option>
																			</select>
																		</div>
																	</div>
																</div>

																<div
																	v-show="fieldIsShown('iban')"
																	class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
																>
																	<label
																		for="username"
																		class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
																	>
																		Type
																		<span v-if="fieldIsRequired('iban')" class="text-red-600"
																			>*</span
																		>
																	</label>
																	<div class="mt-1 sm:mt-0 sm:col-span-2">
																		<div class="max-w-lg flex">
																			<multiselect
																				v-model="selectedIBAN"
																				:options="ibanTypesArray"
																				:multiple="true"
																				:close-on-select="false"
																				:clear-on-select="false"
																				:preserve-search="true"
																				:taggable="true"
																				:select-label="''"
																				:deselect-label="''"
																				:placeholder="''"
																				:class="[
																					ibanError ? 'border-red-600' : 'border-gray-300',
																					'rounded-md shadow-xs w-full focus:ring-primary'
																				]"
																			>
																			</multiselect>
																		</div>
																	</div>
																</div>

																<div
																	v-show="fieldIsShown('optOutType')"
																	class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
																>
																	<label
																		for="username"
																		class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
																	>
																		Type
																		<span v-if="fieldIsRequired('optOutType')" class="text-red-600"
																			>*</span
																		>
																	</label>
																	<div class="mt-1 sm:mt-0 sm:col-span-2">
																		<div class="max-w-lg flex">
																			<select
																				v-model="selectedOptOutType"
																				:class="[
																					optOutTypeError ? 'border-red-600' : 'border-gray-300',
																					'rounded-md shadow-xs w-full focus:ring-primary'
																				]"
																			>
																				<option
																					v-for="item in optOutEmailTypesArray"
																					:key="item"
																					:value="item"
																				>
																					{{ item }}
																				</option>
																			</select>
																		</div>
																	</div>
																</div>

																<div
																	v-show="fieldIsShown('datum')"
																	class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
																>
																	<label
																		for="about"
																		class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
																	>
																		Datum wijziging eigendom
																		<span v-if="fieldIsRequired('datum')" class="text-red-600"
																			>*</span
																		>
																	</label>
																	<div class="mt-1 sm:mt-0 sm:col-span-2">
																		<v-date-picker
																			v-model="selectedDatum"
																			locale="nl"
																			class="inline-block h-full"
																		>
																			<template #default="{ inputValue, togglePopover }">
																				<div class="flex items-center">
																					<button
																						class="p-2 bg-blue-100 border border-sky-200 hover:bg-sky-200 text-primary rounded-l focus:bg-sky-500 focus:text-white focus:border-primary focus:outline-hidden"
																						@click.prevent="togglePopover()"
																					>
																						<svg
																							xmlns="http://www.w3.org/2000/svg"
																							viewBox="0 0 20 20"
																							class="w-4 h-4 fill-current"
																						>
																							<path
																								d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
																							></path>
																						</svg>
																					</button>
																					<input
																						:value="inputValue"
																						class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-hidden focus:border-sky-500 cursor-pointer"
																						:class="[
																							datumError ? 'border-red-600' : 'border-gray-300',
																							'shadow-xs w-full'
																						]"
																						placeholder="dd-mm-yyyy"
																						@click.prevent="togglePopover()"
																						@input="dateEntered"
																					/>
																				</div>
																			</template>
																		</v-date-picker>
																	</div>
																</div>

																<div
																	v-show="fieldIsShown('reason')"
																	class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
																>
																	<label
																		for="about"
																		class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
																	>
																		Reden
																		<span v-if="fieldIsRequired('reason')" class="text-red-600"
																			>*</span
																		>
																	</label>
																	<div class="mt-1 sm:mt-0 sm:col-span-2">
																		<textarea
																			name="about"
																			rows="3"
																			:class="[
																				redenError ? 'border-red-600' : 'border-gray-300',
																				'max-w-lg shadow-xs block w-full focus:ring-primary focus:border-sky-500 sm:text-sm border rounded-md'
																			]"
																			@change="redenChanged"
																		/>
																	</div>
																</div>

																<div
																	v-if="fieldIsShown('file')"
																	class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
																>
																	<label
																		for="cover-photo"
																		class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
																	>
																		{{ bijlageDescription() }}
																		<span v-if="fieldIsRequired('file')" class="text-red-600"
																			>*</span
																		><br />
																		<span class="font-light italic">Max 25MB</span>
																	</label>
																	<div class="mt-1 sm:mt-0 sm:col-span-2">
																		<div
																			:class="[
																				fileError ? 'border-red-600' : 'border-gray-300',
																				'max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md'
																			]"
																		>
																			<label
																				v-show="!selectedFile"
																				for="file-upload"
																				class="relative cursor-pointer bg-white py-2 px-4 border border-gray-300 rounded-md shadow-xs text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-primary"
																			>
																				<span>Een bestand uploaden</span>
																				<input
																					id="file-upload"
																					name="file-upload"
																					type="file"
																					class="sr-only"
																					@change="fileChanged"
																				/>
																			</label>
																			<div
																				v-if="selectedFile"
																				class="flex content-center items-center"
																			>
																				<div class="flex-1">
																					{{ selectedFile.name }}
																				</div>
																				<a href="#" class="flex-initial" @click="removeFile()">
																					<XCircleIcon
																						class="ml-2 h-6 w-6 text-red-600"
																						aria-hidden="true"
																					/>
																				</a>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
										<div
											v-if="success"
											class="mx-auto mt-6 flex items-center justify-center h-12 w-12 rounded-full"
										>
											<CheckIcon class="h-8 w-8 text-primary" aria-hidden="true" />
										</div>
										<p v-if="success" class="mt-3">
											SwifterwinT is op de hoogte van uw nieuwe gegevens en zal contact met u
											opnemen om te bevestigen wanneer het is geupdate.
										</p>
									</div>
								</div>
							</div>
							<div class="text-center text-red-600 text-sm mt-3 mx-3">
								{{ errorMsg }}
							</div>
							<div v-if="!loading && !success" class="mt-5 sm:mt-6 flex">
								<button
									type="button"
									class="inline-flex justify-center flex-1 rounded-md border shadow-xs px-4 py-2 mr-2 bg-white text-base font-medium hover:bg-gray-100 focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:text-sm"
									@click="close()"
								>
									Annuleren
								</button>
								<button
									type="button"
									class="inline-flex justify-center flex-1 rounded-md border border-transparent shadow-xs px-4 py-2 ml-2 bg-primary text-base font-medium text-white hover:bg-sky-700 focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:text-sm"
									@click="save()"
								>
									Doorgaan
								</button>
							</div>
							<div v-if="success" class="mt-5 sm:mt-6 flex">
								<button
									type="button"
									class="inline-flex justify-center flex-1 rounded-md border border-transparent shadow-xs px-4 py-2 ml-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:text-sm"
									@click="close()"
								>
									Sluit
								</button>
							</div>
						</div>
					</TransitionChild>
				</div>
			</Dialog>
		</TransitionRoot>
		<ErrorDialog ref="errorPrompt" />
	</div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
import ErrorDialog from './ErrorDialog.vue'
import { DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { PencilSquareIcon, XCircleIcon } from '@heroicons/vue/24/outline'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import datastormsService from '@/services/datastormsService'
import moment from 'moment'
import Multiselect from 'vue-multiselect'

export default defineComponent({
	components: {
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		PencilSquareIcon,
		XCircleIcon,
		ErrorDialog,
		PulseLoader,
		Multiselect
	},
	props: {
		showPrompt: {
			type: Boolean,
			default: false
		}
	},
	emits: ['close'],
	setup() {
		const errorPrompt: any = ref(null)
		const { t } = useI18n({
			inheritLocale: true,
			useScope: 'global'
		})
		return {
			t,
			errorPrompt
		}
	},
	data() {
		return new (class {
			parceel: string = ''
			org: string = ''
			orgNumber: string = ''
			loading: boolean = false
			success: boolean = false

			selectedCategory: any = null
			selectedIBAN: string[] = []
			selectedReden: string = ''
			selectedFile: any = null
			selectedDatum: any = null
			selectedOptOutType: any = null

			categoryError: boolean = false
			ibanError: boolean = false
			redenError: boolean = false
			fileError: boolean = false
			datumError: boolean = false
			optOutTypeError: boolean = false

			errorMsg: string = ''

			items = {
				3: { id: 3, val: 'Eigendom is veranderd' },
				4: { id: 4, val: 'IBAN is gewijzigd' },
				5: { id: 5, val: 'BTW is gewijzigd' },
				6: { id: 6, val: 'Ik mis een perceel' },
				7: { id: 7, val: 'Afmelding van email berichten' },
				8: { id: 8, val: 'Andere reden' }
			}

			ibanTypesArray = ['Certificaathouder IBAN', 'Lening IBAN', 'Vergoedingen IBAN']
			optOutEmailTypesArray = ['Nieuwsbrief', 'Overige mail', 'Alle']
		})()
	},
	computed: {
		...mapGetters({
			user: 'getCurrentUser'
		})
	},
	methods: {
		dateEntered(e: any) {
			if (moment(e.target.value, 'DD-MM-YYYY', true).isValid()) {
				this.selectedDatum = moment(e.target.value, 'DD-MM-YYYY', true).toDate()
			} else {
				if (e.target.value.length === 2 || e.target.value.length === 5) {
					e.target.value += '-'
				}
			}
		},
		fieldIsRequired(field: 'category' | 'reason' | 'file' | 'datum' | 'iban' | 'optOutType') {
			switch (field) {
				case 'category':
					return true
				case 'reason':
					return true
				case 'iban':
					return this.selectedCategory && this.selectedCategory.id === 4
				case 'optOutType':
					return this.selectedCategory && this.selectedCategory.id === 7
				case 'file':
					return (
						this.selectedCategory &&
						(this.selectedCategory.id === 4 ||
							this.selectedCategory.id === 5 ||
							this.selectedCategory === 6)
					)
				case 'datum':
					return this.selectedCategory && this.selectedCategory.id === 3
			}
		},
		fieldIsShown(field: 'category' | 'reason' | 'file' | 'datum' | 'iban' | 'optOutType') {
			switch (field) {
				case 'category':
					return true
				case 'reason':
					return true
				case 'iban':
					return this.selectedCategory && this.selectedCategory.id === 4
				case 'optOutType':
					return this.selectedCategory && this.selectedCategory.id === 7
				case 'file':
					return !(
						this.selectedCategory &&
						(this.selectedCategory.id === 3 || this.selectedCategory.id === 7)
					)
				case 'datum':
					return this.selectedCategory && this.selectedCategory.id === 3
			}
		},
		bijlageDescription() {
			if (this.selectedCategory && this.selectedCategory.id === 4) {
				return 'Kopie bankafschrift'
			} else if (this.selectedCategory && this.selectedCategory.id === 5) {
				return 'Brief belastingdienst'
			}
			return 'Bijlagen'
		},
		save() {
			this.categoryError = false
			this.redenError = false
			this.fileError = false
			this.ibanError = false
			this.datumError = false
			this.optOutTypeError = false

			if (!this.selectedCategory && this.fieldIsRequired('category')) {
				this.categoryError = true
			}
			if ((!this.selectedIBAN || this.selectedIBAN.length <= 0) && this.fieldIsRequired('iban')) {
				this.ibanError = true
			}
			if (!this.selectedReden && this.fieldIsRequired('reason')) {
				this.redenError = true
			}
			if (!this.selectedFile && this.fieldIsRequired('file')) {
				this.fileError = true
			}
			if (!this.selectedOptOutType && this.fieldIsRequired('optOutType')) {
				this.optOutTypeError = true
			}
			if (!this.selectedDatum && this.fieldIsRequired('datum')) {
				this.datumError = true
			}

			if (
				!this.categoryError &&
				!this.redenError &&
				!this.fileError &&
				!this.datumError &&
				!this.ibanError &&
				!this.optOutTypeError
			) {
				this.loading = true

				var datumString = ''
				if (this.selectedDatum) {
					const options = {
						weekday: 'long',
						year: 'numeric',
						month: 'long',
						day: 'numeric'
					}
					datumString = this.selectedDatum.toLocaleDateString('nl-NL', options)
				}

				let ibanVal = ''
				if (this.selectedIBAN) {
					this.selectedIBAN.forEach(x => {
						ibanVal += ibanVal.length > 0 ? ', ' + x : x
					})
				}

				var data = datastormsService.uploadWijzigen(
					this.user.datastormsKey,
					this.selectedCategory.val,
					ibanVal,
					this.selectedOptOutType,
					this.selectedReden,
					datumString,
					this.selectedFile ? this.selectedFile.data : '',
					this.selectedFile ? this.selectedFile.name : '',
					this.selectedFile ? this.selectedFile.type : '',
					null,
					''
				)
				var parent = this
				Promise.resolve(data).then(function (value: any) {
					parent.loading = false
					if (value.status === 200 && value) {
						parent.selectedCategory = null
						parent.selectedReden = ''
						parent.selectedIBAN = []
						parent.selectedFile = null
						parent.selectedDatum = null
						parent.selectedOptOutType = null
						parent.success = true
					} else {
						parent.errorPrompt.showDialog()
					}
				}, null)
			}
		},
		fileChanged(val: any) {
			var file = val.target.files[0]
			this.errorMsg = ''
			if (val.target.files[0].size > 25000000) {
				this.errorMsg = 'Het grootste bestand dat kan worden verzonden is 25 MB'
			} else {
				const fileReader = new FileReader()
				fileReader.addEventListener('load', () => {
					this.selectedFile.data = fileReader.result
				})
				fileReader.readAsDataURL(file)
				this.selectedFile = file
			}
		},
		redenChanged(val: any) {
			this.selectedReden = val.target.value
		},
		removeFile() {
			this.selectedFile = null
		},
		close() {
			this.selectedCategory = null
			this.selectedReden = ''
			this.selectedIBAN = []
			this.selectedFile = null
			this.selectedDatum = null
			this.selectedOptOutType = null
			this.success = false
			this.$emit('close')
		}
	}
})
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
