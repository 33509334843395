import { Commit } from 'vuex'
import securityService from '@/services/securityService'

export interface ICredentials {
	username: string
	password: string
}

export interface ITOTPData {
	token: string
	code: string
	mode: string
}

export interface IUserState {
	previousUser: string
	currentUser: any
	woningAccordingID: string
	perceelAccordingID: string
}

const users = {
	state: (): IUserState => ({
		previousUser: '',
		currentUser: {},
		woningAccordingID: '',
		perceelAccordingID: ''
	}),
	getters: {
		getWoningAccordingID: (state: IUserState) => {
			return state.woningAccordingID
		},
		getPerceelAccordingID: (state: IUserState) => {
			return state.perceelAccordingID
		},
		getCurrentUser: (state: IUserState) => {
			return state.currentUser
		},
		isLoggedIn: (state: IUserState) => {
			return (
				state.currentUser &&
				state.currentUser.accessToken &&
				state.currentUser.accessToken.toString().length > 0
			)
		},
		accessToken: (state: IUserState) => {
			return state.currentUser.accessToken
		}
	},
	mutations: {
		setWoningAccordingID: (state: IUserState, id: string) => {
			state.woningAccordingID = id
		},
		setPerceelAccordingID: (state: IUserState, id: string) => {
			state.perceelAccordingID = id
		},
		setPreviousUser: (state: IUserState, user: any) => {
			state.previousUser = user
		},
		setCurrentUser: (state: IUserState, user: any) => {
			state.currentUser = user
		}
	},
	actions: {
		authenticateLogin({ commit }: { commit: Commit }, credentials: ICredentials) {
			const data = securityService.authenticateLogin(credentials.username, credentials.password)

			return Promise.resolve(data).then(function (value) {
				if (value.status === 200 && value && value.data) {
					commit('setTempUser', value.data)
				} else {
					if (value.status === 401) {
						return 'Onjuist wachtwoord of emailadres, probeer opnieuw'
					} else {
						return 'Er is een storing met Mijn SwifterwinT, probeer het later nog eens'
					}
				}
			}, null)
		},
		authenticateTotp({ commit }: { commit: Commit }, rawdata: ITOTPData) {
			const data = securityService.authenticateTotp(rawdata.token, rawdata.code, rawdata.mode)

			return Promise.resolve(data).then(function (value: any) {
				if (value.status === 200 && value && value.data && value.data.accessToken) {
					const user = value.data.user
					user.accessToken = value.data.accessToken
					user.loginToken = ''
					commit('setCurrentUser', user)
					commit('setTempUser', {})
				} else {
					//reject(data)
				}
			}, null)
		}
	}
}

export default users
