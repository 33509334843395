/* eslint no-console: 1 */
import Axios from 'axios'
import baseService from '@/services/baseService'

const datastormsService = {
	async getBerichten(puid: string) {
		const config = baseService.generateConfigData()
		return await Axios.get(
			`${import.meta.env.VITE_APP_API_URL}datastorms/berichten?PUID=${puid}`,
			config
		)
	},
	async getAgenda(puid: string) {
		const config = baseService.generateConfigData()
		return await Axios.get(
			`${import.meta.env.VITE_APP_API_URL}datastorms/agenda?PUID=${puid}`,
			config
		)
	},
	async getLoanInfo(puid: string) {
		const config = baseService.generateConfigData()
		return await Axios.get(
			`${import.meta.env.VITE_APP_API_URL}datastorms/getLoanInfo?PUID=${puid}`,
			config
		)
	},
	async getCerticates(puid: string) {
		const config = baseService.generateConfigData()
		return await Axios.get(
			`${import.meta.env.VITE_APP_API_URL}datastorms/certificaten?PUID=${puid}`,
			config
		)
	},
	async getDividends(puid: string) {
		const config = baseService.generateConfigData()
		return await Axios.get(
			`${import.meta.env.VITE_APP_API_URL}datastorms/dividends?PUID=${puid}`,
			config
		)
	},
	async getDocumenten(puid: string) {
		const config = baseService.generateConfigData()
		return await Axios.get(
			`${import.meta.env.VITE_APP_API_URL}datastorms/documenten?PUID=${puid}`,
			config
		)
	},
	async getDocument(id: string) {
		const config = baseService.generateConfigData()
		return await Axios.get(
			`${import.meta.env.VITE_APP_API_URL}datastorms/document?ID=${id}`,
			config
		)
	},
	async getUserData(puid: string) {
		const config = baseService.generateConfigData()
		return await Axios.get(
			`${import.meta.env.VITE_APP_API_URL}datastorms/userData?PUID=${puid}`,
			config
		)
	},
	async updatePhoneNumber(datastormsKey: string, phoneNumber: string) {
		const config = baseService.generateConfigData()
		const payload = {
			datastormsKey: datastormsKey,
			phoneNumber: phoneNumber
		}
		return await Axios.post(
			`${import.meta.env.VITE_APP_API_URL}datastorms/updatePhoneNumber`,
			payload,
			config
		)
	},
	async updateEmail(datastormsKey: string, email: string) {
		const config = baseService.generateConfigData()
		const payload = {
			datastormsKey: datastormsKey,
			email: email //,
			//validationCode: code
		}
		return await Axios.post(
			`${import.meta.env.VITE_APP_API_URL}datastorms/updateEmail`,
			payload,
			config
		)
	},
	async verifyBankAccount(
		organisationID: string,
		bankAccountType: 'iban' | 'iban cfh' | 'iban lening'
	) {
		const config = baseService.generateConfigData()
		const payload = {
			organisationID: organisationID,
			bankAccountType: bankAccountType
		}
		return await Axios.post(
			`${import.meta.env.VITE_APP_API_URL}datastorms/verifyBankAccount`,
			payload,
			config
		)
	},
	async updateEmailRequest(datastormsKey: string, email: string) {
		const config = baseService.generateConfigData()
		const payload = {
			datastormsKey: datastormsKey,
			email: email
		}
		return await Axios.post(
			`${import.meta.env.VITE_APP_API_URL}datastorms/updateEmailRequest`,
			payload,
			config
		)
	},
	async updateUserPassword(datastormsKey: string, currentPassword: string, newPassword: string) {
		const config = baseService.generateConfigData()
		const payload = {
			datastormsKey: datastormsKey,
			currentPassword: currentPassword,
			newPassword: newPassword
		}
		return await Axios.post(
			`${import.meta.env.VITE_APP_API_URL}auth/updateUserPassword`,
			payload,
			config
		)
	},
	async uploadWijzigen(
		datastormsKey: string,
		category: string,
		ibanType: string,
		optOutType: string,
		reden: string,
		datum: string,
		file: any,
		fileName: string,
		fileType: string,
		data: any,
		dataH: string
	) {
		const config = baseService.generateConfigData()
		const payload = {
			datastormsKey: datastormsKey,
			category: category,
			ibanType: ibanType,
			optOutType: optOutType,
			reden: reden,
			datum: datum,
			file: file,
			fileName: fileName,
			fileType: fileType,
			data: data,
			dataH: dataH
		}
		return await Axios.post('datastorms/uploadWijzigen', payload, config)
	}
}

export default datastormsService
