<template>
	<div>
		<div v-if="loggedIn" class="min-h-screen">
			<div class="flex flex-col min-h-screen gap-10">
				<div>
					<Popover
						v-slot="{ open }"
						as="header"
						class="p-3 mt-8 lg:mt-1 bg-white drop-shadow-md pb-12 lg:pb-4"
					>
						<div class="mx-auto pr-6 w-full">
							<div class="relative flex flex-wrap items-center justify-center lg:justify-between">
								<!-- Logo -->
								<div class="absolute left-0 py-5 shrink-0 hover:cursor-pointer lg:static">
									<a @click="HomeClicked">
										<img
											class="h-16 md:h-20 lg:h-32 -mb-8 -mt-8 w-auto"
											src="./assets/logo.png"
											alt="Workflow"
										/>
									</a>
								</div>

								<!-- Right section on desktop -->
								<div class="hidden lg:ml-4 lg:flex lg:items-center lg:py-5 lg:pr-0.5 p-8">
									<!-- Profile dropdown -->
									<Menu as="div" class="ml-4 relative shrink-0">
										<div class="flex-initial">
											<MenuButton
												class="bg-white rounded-full flex text-sm ring-2 ring-primary-green ring-opacity-40 focus:outline-hidden focus:ring-opacity-100 p-1"
											>
												<span class="sr-only">Open user menu</span>
												<UserCircleIcon class="h-8 w-8 rounded-full" />
												<div class="flex-1 mt-1 mr-2">{{ user.name }}</div>
											</MenuButton>
										</div>
										<transition
											leave-active-class="transition ease-in duration-75"
											leave-from-class="transform opacity-100 scale-100"
											leave-to-class="transform opacity-0 scale-95"
										>
											<MenuItems
												class="origin-top-right z-40 absolute -right-2 mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-hidden"
											>
												<MenuItem v-for="item in userNavigation" :key="item.name">
													<a
														:href="item.href"
														:class="[
															'block px-4 py-2 text-base text-gray-800 hover:bg-gray-100 focus:bg-gray-400',
															item.separator
																? 'border-grey-200 border-t mt-2 border-dashed font-medium'
																: ''
														]"
														@click="
															_ => {
																navigationClick(item)
															}
														"
														>{{ item.name }}</a
													>
												</MenuItem>
											</MenuItems>
										</transition>
									</Menu>
								</div>

								<!-- Menu button -->
								<div class="absolute right-0 shrink-0 lg:hidden">
									<!-- Mobile menu button -->
									<PopoverButton
										class="bg-transparent p-2 rounded-md inline-flex items-center justify-center text-sky-200 hover:text-sky hover:bg-white hover:bg-opacity-10 focus:outline-hidden focus:ring-2 focus:ring-sky"
									>
										<span class="sr-only">Open main menu</span>
										<Bars3Icon
											v-if="!open"
											class="block h-8 w-8 text-primary focus:outline-hidden focus:ring-2 focus:ring-sky"
											aria-hidden="true"
										/>
										<XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
									</PopoverButton>
								</div>
							</div>
						</div>

						<TransitionRoot as="template" :show="open">
							<div class="lg:hidden">
								<TransitionChild
									as="template"
									enter="duration-150 ease-out"
									enter-from="opacity-0"
									enter-to="opacity-100"
									leave="duration-150 ease-in"
									leave-from="opacity-100"
									leave-to="opacity-0"
								>
									<PopoverOverlay static class="z-20 fixed inset-0 bg-black bg-opacity-25" />
								</TransitionChild>

								<TransitionChild
									as="template"
									enter="duration-150 ease-out"
									enter-from="opacity-0 scale-95"
									enter-to="opacity-100 scale-100"
									leave="duration-150 ease-in"
									leave-from="opacity-100 scale-100"
									leave-to="opacity-0 scale-95"
								>
									<PopoverPanel
										focus
										static
										class="z-30 absolute top-0 inset-x-0 max-w-5xl mx-auto w-full p-2 transition transform origin-top"
									>
										<div
											class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200"
										>
											<div class="pt-3 pb-2">
												<div class="flex items-center justify-between px-4">
													<div>
														<img class="h-12 w-auto" src="./assets/logo.png" alt="Workflow" />
													</div>
													<div class="-mr-2">
														<PopoverButton
															class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-hidden focus:ring-2 focus:ring-inset focus:ring-cyan-500"
														>
															<span class="sr-only">Close menu</span>
															<XMarkIcon class="h-6 w-6" aria-hidden="true" />
														</PopoverButton>
													</div>
												</div>
												<!-- <div class="mt-3 px-2 space-y-1">
													<a
														v-for="item in navigation"
														:key="item.name"
														:href="item.href"
														class="
															block
															rounded-md
															px-3
															py-2
															text-base text-gray-900
															font-medium
															hover:bg-gray-100 hover:text-gray-800
														"
														>{{ item.name }}</a
													>
												</div> -->
											</div>
											<div class="pt-4 pb-2">
												<div class="flex items-center px-5 p-8">
													<span>{{ user.name }}</span>
													<div class="shrink-0">
														<UserCircleIcon class="h-8 w-8 rounded-full" />
													</div>
													<button
														class="ml-auto shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
													>
														<span class="sr-only">View notifications</span>
													</button>
												</div>
												<div class="mt-3 px-2 space-y-1">
													<a
														v-for="item in userNavigation"
														:key="item.name"
														:href="item.href"
														class="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
														@click="
															_ => {
																navigationClick(item)
															}
														"
														>{{ item.name }}</a
													>
												</div>
											</div>
										</div>
									</PopoverPanel>
								</TransitionChild>
							</div>
						</TransitionRoot>
					</Popover>
					<div class="h-12 bg-primary-green w-full flex">
						<div class="mx-auto flex gap">
							<div v-for="(item, index) in headerNavItems" :key="item.title" class="my-auto flex">
								<div v-if="index <= 0" class="text-white font-bold mt-0.5">|</div>
								<AppButton
									:Text="item.title"
									Size="medium"
									Background="none"
									:Icon="item.icon"
									@clicked="headerButton_Click(item)"
								/>
								<div class="text-white font-bold mt-0.5">|</div>
							</div>
							<div v-if="showBreadcrumbs" class="my-auto flex gap">
								<div class="text-white font-bold mt-0.5">|</div>
								<AppButton
									Text="Terug naar Mijn SwifterwinT"
									Size="medium"
									Background="none"
									Icon="ChevronLeftIcon"
									@clicked="btnHome_Click"
								/>

								<div class="text-white font-bold mt-0.5">|</div>
								<AppButton
									v-if="showPortaalBreadcrumb && showNewPortal"
									Text="Terug naar Portaal"
									Size="medium"
									Background="none"
									Icon="ChevronLeftIcon"
									@clicked="btnPortal_Click"
								/>
								<div
									v-if="showPortaalBreadcrumb && showNewPortal"
									class="text-white font-bold mt-0.5"
								>
									|
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="flex-1 flex flex-col max-w-[1400px] mx-auto w-full gap-8">
					<router-view />
				</div>

				<footer class="bg-primary text-white">
					<section class="pt-6 pb-5">
						<div class="pt-6 relative">
							<div class="container mx-auto">
								<div class="flex flex-wrap mr-8 ml-8">
									<div class="flex-1 mr-5">
										<p>
											SwifterwinT is samen met Vattenfall initiatiefnemer van Windplanblauw.
											Bewoners en ondernemers in het projectgebied in de omgeving van Swifterbant
											zijn eigenaar van SwifterwinT. Met ons windpark produceren we de meeste groene
											stroom in lokaal eigendom in Nederland.
										</p>

										<!-- iconen lijst -->
										<div class="pb-3 mt-10 text-sm flex hover:opacity-50">
											<div class="w-3 mr-3">
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
													<path
														fill="#FFF"
														d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"
													/>
												</svg>
											</div>
											<a
												href="https://www.google.com/maps/place/De+Maalstroom+2,+8255+RN+Swifterbant/data=!4m2!3m1!1s0x47c88200d9bcf62d:0xc78985782c3644dc?sa=X&amp;ved=2ahUKEwj6r8KlxIP9AhXHlP0HHSJUAYgQ8gF6BAgXEAI"
												target="_blank"
												>SwifterwinT bv, De Maalstroom 2, 8255 RN Swifterbant</a
											>
										</div>
										<div class="pb-3 text-sm flex hover:opacity-50">
											<div class="w-3 mr-3">
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
													<!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
													<path
														fill="#FFF"
														d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"
													/>
												</svg>
											</div>
											<a href="mailto:secretariaat@swifterwintbv.nl" target="_blank"
												>secretariaat@swifterwintbv.nl</a
											>
										</div>
										<div class="pb-3 text-sm flex hover:opacity-50">
											<div class="w-3 mr-3">
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
													<!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
													<path
														fill="#FFF"
														d="M269.4 2.9C265.2 1 260.7 0 256 0s-9.2 1-13.4 2.9L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.7 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2L269.4 2.9zM160.9 286.2c4.8 1.2 9.9 1.8 15.1 1.8c35.3 0 64-28.7 64-64V160h44.2c12.1 0 23.2 6.8 28.6 17.7L320 192h64c8.8 0 16 7.2 16 16v32c0 44.2-35.8 80-80 80H272v50.7c0 7.3-5.9 13.3-13.3 13.3c-1.8 0-3.6-.4-5.2-1.1l-98.7-42.3c-6.6-2.8-10.8-9.3-10.8-16.4c0-2.8 .6-5.5 1.9-8l15-30zM160 160h40 8v32 32c0 17.7-14.3 32-32 32s-32-14.3-32-32V176c0-8.8 7.2-16 16-16zm128 48c0-8.8-7.2-16-16-16s-16 7.2-16 16s7.2 16 16 16s16-7.2 16-16z"
													/>
												</svg>
											</div>
											<a
												href="https://swifterwint.nl/privacyverklaring-swifterwint/"
												target="_blank"
												>Privacyverklaring SwifterwinT</a
											>
										</div>
									</div>

									<div
										class="alt-col col-12 col-md-4 d-flex justify-content-between flex-column"
										style="align-content: flex-end"
									>
										<div class="text-right">
											<a href="https://swifterwint.nl/"
												><img
													src="https://swifterwint.nl/wp-content/uploads/2023/02/Logo-SWF-wit.svg"
													alt="SwifterwinT"
													class="img-fluid"
											/></a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="mt-0 pb-0 pt-0 text-sm">
							<div class="container mx-auto">
								<div class="flex flex-wrap mr-8 ml-8">
									<div class="py-4 w-full">
										<div class="border-t-2 border-solid border-primary-yellow"></div>
									</div>
								</div>

								<div class="flex flex-wrap mr-8 ml-8">
									<div class="py-2 flex-1">
										<div class="text-primary-green">Copyright © 2023 SwifterwinT BV</div>
									</div>

									<div class="text-right flex">
										<a
											href="https://www.facebook.com/windplanblauw"
											target="_blank"
											class="ml-3 hover:opacity-50"
											><div class="w-3">
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
													<!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
													<path
														fill="#FFF"
														d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
													/>
												</svg></div
										></a>
										<div class="text-primary-yellow ml-2 pb-1">|</div>
										<a
											href="https://www.linkedin.com/company/swifterwint"
											target="_blank"
											class="ml-2 hover:opacity-50"
											><div class="w-4">
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
													<!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
													<path
														fill="#FFF"
														d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
													/>
												</svg></div
										></a>
										<div class="text-primary-yellow ml-2 pb-1">|</div>
										<a
											href="https://www.youtube.com/channel/UCToGzy23pt4U7X_WDHIso5A"
											target="_blank"
											class="ml-2 hover:opacity-50"
											><div class="w-5">
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
													<!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
													<path
														fill="#FFF"
														d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
													/>
												</svg></div
										></a>
									</div>
								</div>
							</div>
						</div>
					</section>
				</footer>
			</div>
		</div>
		<DetailsDialog
			ref="detailsDialog"
			:show-prompt="detailsDialogShown"
			@close="detailsDialogShown = false"
		/>
		<ChangeTelephoneNumberDialog
			ref="telefoonnummerPrompt"
			:show-prompt="gevensDialogShown"
			@close="gevensDialogShown = false"
		/>
		<ChangePasswordDialog
			ref="passwordPrompt"
			:show-prompt="changePasswordDialogShown"
			@close="changePasswordDialogShown = false"
		/>
		<TwoFADialog
			ref="twoFactorPrompt"
			:show-prompt="tfaDialogShown"
			@close="tfaDialogShown = false"
		/>
		<router-view v-if="!loggedIn" />
	</div>
</template>
<script lang="ts">
import {
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	Popover,
	PopoverButton,
	PopoverOverlay,
	PopoverPanel,
	TransitionChild,
	TransitionRoot
} from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/24/solid'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { UserCircleIcon } from '@heroicons/vue/24/solid'
import ChangeTelephoneNumberDialog from '@/components/dialogs/ChangeTelephoneNumberDialog.vue'
import DetailsDialog from '@/components/dialogs/DetailsDialog.vue'
import ChangePasswordDialog from '@/components/dialogs/ChangePasswordDialog.vue'
import TwoFADialog from '@/components/dialogs/2FADialog.vue'
import AppButton from '@/components/core/AppButton.vue'
import IMenuLink from '@/services/interfaces/IMenuLink'

const userNavigation = [
	{ name: 'Inloggegevens veranderen', href: '#', id: 2 },
	{ name: 'Wijziging doorgeven', href: '#', id: 3 },
	{ name: 'Wachtwoord veranderen', href: '#', id: 4 },
	{ name: 'Twee factor authenticatiebeheer ', href: '#', id: 5 },
	{ name: 'Uitloggen', href: '#', id: 1, separator: true }
]

export default {
	components: {
		// eslint-disable-next-line vue/no-reserved-component-names
		Menu,
		MenuButton,
		MenuItem,
		MenuItems,
		Popover,
		PopoverButton,
		PopoverOverlay,
		PopoverPanel,
		TransitionChild,
		TransitionRoot,
		Bars3Icon,
		XMarkIcon,
		UserCircleIcon,
		ChevronRightIcon,
		ChangeTelephoneNumberDialog,
		DetailsDialog,
		ChangePasswordDialog,
		TwoFADialog,
		AppButton
	},
	setup() {
		return {
			userNavigation
		}
	},
	data() {
		return {
			detailsDialogShown: false,
			gevensDialogShown: false,
			changePasswordDialogShown: false,
			tfaDialogShown: false
		}
	},
	computed: {
		loggedIn() {
			return this.$store.getters.isLoggedIn
		},
		user() {
			return this.$store.getters.getCurrentUser
		},
		baseURL() {
			if (import.meta.env) {
				return import.meta.env.BASE_URL
			}
			return '#'
		},
		showBreadcrumbs() {
			return this.$router.currentRoute?.value?.meta?.subpage
		},
		showPortaalBreadcrumb() {
			return (
				this.$router.currentRoute?.value?.fullPath.includes('portaal') &&
				this.$router.currentRoute?.value?.name != 'portaal'
			)
		},
		currentPageName() {
			return this.$router.currentRoute?.value?.meta?.title
		},
		headerNavItems(): Array<IMenuLink> {
			return this.$router.currentRoute?.value?.meta?.headerButtons as Array<IMenuLink>
		},
		showNewPortal() {
			return import.meta.env.VITE_APP_ == 'true'
		}
	},
	methods: {
		btnHome_Click() {
			this.$router.push('/')
		},
		btnPortal_Click() {
			this.$router.push({ name: 'Portaal' })
		},
		headerButton_Click(item: IMenuLink) {
			this.$router.push(item.link)
		},
		navigationClick(c: any) {
			switch (c.id) {
				case 1:
					this.$store.commit('setCurrentUser', {})
					this.$router.push('/login')
					break
				case 2:
					this.gevensDialogShown = true
					break
				case 3:
					this.detailsDialogShown = true
					break
				case 4:
					this.changePasswordDialogShown = true
					break
				case 5:
					this.tfaDialogShown = true
					break
			}
		},
		HomeClicked() {
			this.$router.push('/')
		}
	}
}
</script>
