<template>
	<div
		class="text-white px-6 cursor-pointer flex gap-2"
		:class="[backgroundClasses, sizeClasses]"
		@click="clicked"
	>
		<component :is="heroIcons[Icon]" v-if="Icon" class="h-6 text-xl" />
		<div>{{ Text }}</div>
	</div>
</template>

<script lang="ts">
import { Component, Emit, Prop, toNative, Vue } from 'vue-facing-decorator'
import * as heroIcons from '@heroicons/vue/24/outline'

@Component
class AppButton extends Vue {
	@Prop({ required: true })
	Text: string = ''

	@Prop()
	Icon: string = ''

	@Prop()
	Size: 'medium' | 'large' = 'large'

	@Prop
	Background: 'blue' | 'no background' = 'blue'

	get heroIcons(): { [name: string]: unknown } {
		return heroIcons
	}

	get backgroundClasses() {
		return this.Background == 'blue' ? 'bg-sky-800 hover:bg-sky-600 rounded-lg' : 'hover:opacity-80'
	}

	get sizeClasses() {
		return this.Size == 'medium' ? 'text-base p-1' : 'p-3 text-lg'
	}

	mounted() {}

	@Emit
	clicked() {
		return 'clicked'
	}
}

export default toNative(AppButton)
</script>

<style></style>
